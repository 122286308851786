import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { ContentRow } from '../components/ContentRow';
import { ContentRowWithVideo } from '../components/ContentRowWithVideo';
import { Layout } from '../components/Layout';
import { ProjectGrid } from '../components/ProjectGrid';
import ProjectSlider from '../components/ProjectSlider';
import { linkResolver } from '../core-ui/utils/linkResolver';
import { isLast } from '../utils/isLast';
import { HomeQuery, ProjectFragment } from '../__generated__/types';

type Data = { data: HomeQuery };

const HomeTemplate = ({ data }: Data) => (
  <Layout title="Home">
    <Home data={data} />
  </Layout>
);

const Home = ({ data: { prismicPage, prismicSiteSettings } }: Data) => {
  const body = prismicPage?.data.body;
  const jargon = prismicSiteSettings?.data.jargon;
  return (
    <>
      {body?.map((item, index) => {
        const last = isLast(index, body as Array<unknown>);
        if (item.__typename === 'PrismicPageDataBodyProjectSlider') {
          return (
            <ProjectSlider
              projectSlider={item}
              key={item.__typename}
              jargon={jargon?.richText || []}
              last={last}
            />
          );
        }
        if (item.__typename === 'PrismicPageDataBodyCustomContent') {
          return (
            <ContentRow data={item} jargon={jargon?.richText} last={last} />
          );
        }
        if (item.__typename === 'PrismicPageDataBodyCustomContentVideo') {
          return (
            <ContentRowWithVideo
              data={item}
              last={last}
              nextSliceBg={
                body?.[index + 1].__typename ===
                'PrismicPageDataBodyProjectGrid'
                  ? 'gray'
                  : 'white'
              }
            />
          );
        }
        if (item.__typename === 'PrismicPageDataBodyProjectGrid') {
          const projectGrid = item.items
            ?.map((item) => {
              if (
                item.project_item?.document?.__typename === 'PrismicProject'
              ) {
                return item.project_item.document;
              }
            })
            .filter((item) => item != null) as Array<ProjectFragment>;

          return (
            <ProjectGrid
              projects={projectGrid}
              last={last}
              sectionTitle={item.primary?.section_title}
              actionButtonTitle={item.primary?.action_button_title || undefined}
              linkTo="/projects"
            />
          );
        }

        return null;
      })}
    </>
  );
};

export const query = graphql`
  query Home {
    prismicPage(uid: { eq: "home" }) {
      _previewable
      id
      uid
      data {
        body {
          ...ProjectSliderFragment
          ...PageBodyCustomContent
          ...ContentRowWithVideo
          ...ProjectGrid
        }
      }
    }
    prismicSiteSettings {
      data {
        jargon {
          text
          html
          richText
        }
      }
    }
  }
`;

export default withPrismicPreview<Data['data'], PageProps<Data['data']>>(
  HomeTemplate,
  [
    {
      repositoryName: 'scandidesign',
      linkResolver: linkResolver,
    },
  ],
);
